.Loading {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Loading img {
    height: 35vmin;
}

@media (prefers-reduced-motion: no-preference) {
    .Loading img {
      animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}