.App {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.App-logo {
  height: 20vmin;
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h1 {
  cursor: pointer;
}

.Share {
  position: fixed;
  right: 10px;
  bottom: 10px;
}
