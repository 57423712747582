.Post {
    border-style: solid;
    border-color: black;
    border-radius: 10px;
    margin-top: 5px;
}

.PostContent {
    margin: 3%;
}

.Author {
    color: gray;
}