.Share {
    color: white;
    background-color: hotpink;
    border-radius: 10px;
    padding-left: 10px;
    min-width: 25vh;
    display: flex;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    cursor: pointer;
  }
  
  .Share img {
    height: 10vmin;
  }